"use client";

// We use the RequestInit type to shape the fetch parameters. We do, however,
// rewrite `body` and `method` to our own definition
type ClientJsonFetcherProps<BodyType> = Omit<RequestInit, "body" | "method"> & {
  readonly body?: BodyType;
  readonly method?: "DELETE" | "GET" | "POST" | "PUT";
  readonly recaptchaToken?: string;
};

export async function clientFetcher<BodyType>(
  url: string,
  {
    body,
    method = "GET",
    recaptchaToken,
    headers = {},
    ...rest
  }: ClientJsonFetcherProps<BodyType>,
) {
  return fetch(url, {
    ...(body !== undefined && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      ...(recaptchaToken !== undefined && {
        "X-Recaptcha-Token": recaptchaToken,
      }),
      ...headers,
    },
    method,
    ...rest,
  });
}
